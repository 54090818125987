// Roboto Condensed

@font-face {
  font-family: "roboto-condensed-light";
  src: url("../../Assets/Typo/Roboto-Condensed/RobotoCondensed-Light.ttf");
}
@font-face {
  font-family: "roboto-condensed-regular";
  src: url("../../Assets/Typo/Roboto-Condensed/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "roboto-condensed-bold";
  font-weight: bold;
  src: url("../../Assets/Typo/Roboto-Condensed/RobotoCondensed-Bold.ttf");
}

// Amatic SC

@font-face {
  font-family: "amatic-bold";
  src: url("../../Assets/Typo/Amatic_SC/AmaticSC-Bold.ttf");
}

@font-face {
  font-family: "amatic-regular";
  src: url("../../Assets/Typo/Amatic_SC/AmaticSC-Regular.ttf");
}
