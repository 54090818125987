// Colors

$black: #16181b;
$primary-black: #353a3a;
$scndry-black: #454545;
$pelorous: #3fadba;
$orange: #e4c063;
$green: #89a235;
$brown: #c18b7c;
$dark-brown: #622716;
$dark-green: #2f5a5f;
$purple: #626e8b;
