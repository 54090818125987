@media only screen and (min-width: 740px) {
  #fernweh-page {
    .fernweh-story {
      .fernweh-main {
        .travel-tips-wrapper {
          .travel-info {
            .activity-section {
              .travel-info-cards-container {
                .no-carousel-container {
                  display: flex;
                  margin: 2rem 0 2rem 3.7rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
