@media only screen and (min-width: 1100px) {
  // Home - About us

  .aboutUs-instagram-wrapper {
    padding: 0 0 6rem 8%;
  }
  #fernweh-section {
    padding: 5rem 0 9rem 8%;
  }
  #reasons-section {
    padding: 5rem 0 7rem 8%;
    .BrainhubCarousel__container {
      .BrainhubCarousel {
        .BrainhubCarousel__customArrows,
        .BrainhubCarousel__custom-arrowLeft {
          left: 9.5%;
        }
      }
    }
  }

  // Home - Theorie

  #theorie-section {
    padding: 8rem 0 11rem 8%;
  }

  // Home - Friends

  #friends-section {
    margin: 0 0 11rem 8%;
  }

  // Home - Sponsors and partners

  #sponsors-partners-section {
    #sponsors-heading-box {
      margin-left: 8%;
    }
    #partners-heading-box {
      margin: 0 0 5rem 8%;
    }
  }

  // Theorie stand alone

  #theorie-page-wrapper {
    .theorie-page-intro {
      margin: 15rem 0 9rem 16%;
      display: grid;
      grid-template-columns: [txt] 2fr [img] 2.7fr;
      grid-template-rows: [row] 1fr;
      max-width: 1000px;
    }
    .theorie-page-text {
      .heading-paragraph-box {
        margin: 0 6.5rem 0 0;
      }
    }
  }

  // Media stand alone

  #media-page-wrapper {
    padding: 0 0 6rem 15%;
    .download-section {
      grid-template-columns: [col-1] 2.4fr [col-2] 1.8fr;
    }
  }
}
