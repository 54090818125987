@media only screen and (min-width: 1400px) {
  .aboutUs-instagram-wrapper {
    #about-us-section {
      max-width: 1245px;
    }
  }
  #fernweh-section {
    max-width: 1265px;
  }

  #theorie-section {
    max-width: 1245px;
  }
}
